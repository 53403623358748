//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  homeProductCategorys,
  brandList,
  recordText,
  categoryList,
  homeBanner,
  bannerList
} from '~/api/home';
import { setSession, setCookie, getCookie, setStore } from '~/utils/storage';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { fetchList } from '~/api/home';
// import advertisingSpace from '~/components/home/advertisingSpace.vue';
import storeyOne from '~/components/home/storeyOne.vue';
import storey from '~/components/home/storey.vue';
import Nav from '~/components/nav';
import floorSuspension from '~/components/home/floorSuspension';
import rightSuspension from '~/components/home/rightSuspension';
import { getPrivacy } from '~/api/qualificationsType';
export default {
  components: {
    storeyOne,
    storey,
    Nav,
    floorSuspension,
    rightSuspension
  },
  async asyncData({ store }) {
    let [floorList, hotBrandData] = await Promise.all([
      /**
       * 获取首页楼层列表
       */
      homeProductCategorys()
        .then((data) => {
          if (data.code == '0000') {
            console.log('楼层'+data);
            return data.data;
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      /**
       * 获取热门品牌
       */
      brandList()
        .then((data) => {
          if (data.code == '0000') {
            console.log('获取热门品牌'+data);
            return data.data.slice(0, 6);
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      /**
       * 热门搜索列表
       */
      recordText()
        .then((data) => {
          if (data.code == '0000') {
            store.commit('home/setHotList', data.data);
          } else {
            store.commit('home/setHotList', []);
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      /**
       * 获取全部分类
       */
      categoryList({
        recommendFlag: 1,
        shelfStatusFlag: 1
      })
        .then((data) => {
          if (data.code === '0000') {
            console.log('获取热门品牌'+data);
            store.commit('home/setCategoryAll', data.data);
          } else {
            store.commit('home/setCategoryAll', []);
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      /**
       * 首页轮播图、公告
       */
      homeBanner()
        .then((data) => {
          if (data.code === '0000') {
            console.log('公告'+data);
            store.commit('home/setBanner', data.data.ROTATION_BANNER);
            store.commit('home/setNotice', data.data.YDW_NEWS);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    ]).catch((err) => {
      console.log(err);
    });
    try {
      return {
        floorList,
        hotBrandData
      };
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      qualificationsList: [],
      privacyContent: '',
      policyVisible: false, // 隐私权政策弹框
      dialogFormVisible: true,
      result: 0,
      showFloorSuspension: false,
      showRightSuspension: false,
      floorIndex: 0,
      floorNum: 0,
      setVersionDisable: true
    };
  },
  computed: {
    ...mapGetters('user', ['include']),
    ...mapState('user', {
      value: (state) => state.value
    })
  },
  watch: {
    $route(to, from) {
      console.log('path', to.path);
    },
    floorList: {
      handler: function(val, oldVal) {
        if (val && val.length > 0) {
          this.floorNum = val.length + 1;
        } else {
          this.floorNum = 1;
        }
      },
      immediate: true
    }
  },
  created() {
    this.isShowPrivacy();
    this.bannerList();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    moreClick() {
      this.$router.push('/qualifications?index=51');
    },
    bannerList() {
      bannerList({
        typeIds: 'YDW_NEWS',
      }).then((res) => {
        if (res.code == '0000') {
          this.qualificationsList = res.data.YDW_NEWS;
        }
      });
    },
    // 协议滚动条到底部
    scrollEvent(e) {
      console.log(e.srcElement.scrollTop + e.srcElement.clientHeight);
      console.log(e.srcElement.scrollHeight - 200);
      if (e.srcElement.scrollTop + e.srcElement.clientHeight > e.srcElement.scrollHeight - 200) {
        this.setVersionDisable = false;
      }
    },
    // 是否需要显示隐私政策
    isShowPrivacy() {
      getPrivacy({
        contentId: 22
      }).then((res) => {
        if (res.code == '0000') {
          this.version = res.data.version;
          if (this.version != getCookie('priacyVersion')) {
            this.privacyContent = res.data.content;
            setTimeout(() => {
              this.policyVisible = true;
            }, 500);
          }
        }
      });
    },
    // 确认了解隐私权政策
    setVersion() {
      setCookie('priacyVersion', this.version);
      setStore('isUserText', 'isRead');
      this.policyVisible = false;
    },
    goPrivacy() {
      setSession('qualificationsType', 6);
      this.$router.push('/qualifications?index=0');
    },
    goUser() {
      setSession('qualificationsType', 7);
      this.$router.push('/qualifications?index=0');
    },
    /**
     * 回到顶部
     */
    toTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    /**
     * 缓慢移动效果
     */
    moveSlowly(number) {
      if (document.documentElement.scrollTop >= number) {
        let terval = setInterval(() => {
          document.documentElement.scrollTop -= 50;
          if (document.documentElement.scrollTop <= number) {
            clearInterval(terval);
            document.documentElement.scrollTop = number;
          }
        }, 10);
      } else {
        let terval = setInterval(() => {
          document.documentElement.scrollTop += 50;
          if (document.documentElement.scrollTop >= number) {
            clearInterval(terval);
            document.documentElement.scrollTop = number;
          }
        }, 10);
      }
    },
    /**
     * 点击楼层浮动跳转到对应楼层
     */
    jumpFloor(index) {
      if (index === 0) {
        this.moveSlowly(540);
      } else if (index === 1) {
        this.moveSlowly(955);
      } else if (index > 1) {
        this.moveSlowly(955 + (index - 1) * 644);
      }
    },
    /**
     * 监听页面滚动
     */
    handleScroll() {
      if (this.$refs.floorSuspension) {
        let s = this.$refs.floorSuspension.getBoundingClientRect().top;
        let bottom = this.$refs.floorSuspension.getBoundingClientRect().bottom;
        if (s < 0 && bottom > 290) {
          this.showFloorSuspension = true;
        } else {
          this.showFloorSuspension = false;
        }
        if (s < 400) {
          this.showRightSuspension = true;
        } else {
          this.showRightSuspension = false;
        }
        /**
         * 楼层选中的位置
         */
        if (s < 0 && s > -415) {
          this.floorIndex = 0;
        } else if (s < -415 && s > -1000) {
          this.floorIndex = 1;
        } else if (s < -1000) {
          this.floorIndex = parseInt((Math.abs(s) - 415) / 644) + 1;
        }
      }
    },
    getList() {
      fetchList({
        pageIndex: 1,
        pageSize: 20,
        type: 'PROMO'
      }).then((res) => {
        console.log('这是mounted');
      });
    },
    ...mapActions('user', ['getInfo']),
    ...mapMutations('user', ['SET_VALUE'])
  },
  // SEO优化
  head() {
    return {
      title: '药兜网-浙江壹零柒陆药业有限公司',
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: '网上买药,网上购药,网上药店,网上药房,药品网,药兜网'
        },
        {
          hid: 'description',
          name: 'description',
          content:
            '国家药监局认证正规网上药房,为您提供方便的网上买药服务,实体药店供货配送,100%正品保证,买正品药就来药兜网'
        }
      ]
    };
  }
};
