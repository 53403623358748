//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Nav',
  components: {

  },
  data() {
    return {
      bannerData: [
        {
          link: 'https://m.yaodouwang.com/exercise?id=185',
          imageUrl: require('assets/img/home/banner_1.jpg'),
        },
        {
          link: 'https://m.yaodouwang.com/exercise?id=178',
          imageUrl: require('assets/img/home/banner_2.jpg'),
        },
      ]
    };
  },
  watch: {

  },
  created() {
  },
  mounted() {
  },
  methods: {
    goLink() {
      window.open('http://www.chinafcx.com/', '_blank'); // 关键在此
    }
  }
};
