import request from '~/utils/request';

/**
 * 获取商品信息
 * @param {Object}} data
 */
export function getPrivacy(data) {
  return request.http(
    'get', '/supplier/content/getContent', data, 0, {}
  );
}