//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StoreyRight',
  props: {
    rightItemData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
  }
};
