//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StoreyLeft',
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    storeyIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      clickIndex: 0
    };
  },
  methods: {
    leftClick(item, index) {
      this.clickIndex = index;
      this.$emit('leftClick', item);
    }
  }
};
