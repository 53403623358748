//
//
//
//
//
//
//
//
//
//
//
//

import storeyContent from './storeyContent';
export default {
  name: 'Storey',
  components: {
    storeyContent
  },
  props: {
    storeyData: {
      type: Object,
      default: function() {}
    },
    storeyIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
  }
};
