//
//
//
//
//
//
//
//
//

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    floorIndex: {
      type: Number,
      default: 0
    },
    floorNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    jumpFloor(index) {
      this.$emit('jumpFloor', index);
    }
  }
};
