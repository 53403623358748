//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
      userInfo: false
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    toTop() {
      this.$emit('toTop');
    },
    /**
     * 浏览记录
     */
    browsingHistory() {
      if (this.$store.getters.getUserToken()) {
        if (this.$store.getters.getUserInfo().mobile) {
          this.$router.push('/user/browseRecord');
        } else {
          this.$router.push({ path: '/login/bindPhone' });
        }
      } else {
        this.$router.push('/login');
      }
    },
    /**
     * 购物车
     */
    cart() {
      if (this.$store.getters.getUserToken()) {
        if (this.$store.getters.getUserInfo().mobile) {
          this.$router.push('/shoppingCart');
        } else {
          this.$router.push({ path: '/login/bindPhone' });
        }
      } else {
        this.$router.push('/login');
      }
    }
  }
};
