import { render, staticRenderFns } from "./storeyOne.vue?vue&type=template&id=0f045a22&scoped=true&"
import script from "./storeyOne.vue?vue&type=script&lang=js&"
export * from "./storeyOne.vue?vue&type=script&lang=js&"
import style0 from "./storeyOne.vue?vue&type=style&index=0&id=0f045a22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f045a22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StoreyItem: require('/root/workspace/ydw_b2c_pc_E5ea/components/storeyItem.vue').default})
