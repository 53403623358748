//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storeyItem from '../storeyItem';
export default {
  name: 'StoreyOne',
  components: {
    storeyItem
  },
  props: {
    hotBrand: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      data: {}
    };
  }
};
