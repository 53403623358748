//
//
//
//
//
//
//
//

import storeyLeft from './storeyLeft';
import storeyRight from './storeyRight';
export default {
  name: 'StoreyContent',
  components: {
    storeyLeft,
    storeyRight
  },
  props: {
    storeyData: {
      type: Object,
      default: function() {}
    },
    commodityList: {
      type: Object,
      default: () => {}
    },
    storeyIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rightData: []
    };
  },
  mounted() {
    this.rightData = this.storeyData.childCategorys[0].products;
  },
  methods: {
    leftClick(item) {
      this.rightData = item;
    }
  }
};
