//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StoreyItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
